import { useCallback } from 'react';
import Button from '@mui/material/Button';
import { Formik } from 'formik';

import { ProductEditableSection } from '@/components/mui/product-editable-section';
import { useAppDispatch, useAppSelector } from '@/store/app';
import {
  updateProduct,
  deleteProduct,
  toggleProductStatus,
  selectIsUserProductsResourceUpdating,
} from '@/store/domains';
import { productSchemaValidator } from '@/utils/validators';
import { ProductStatus } from '@/utils/constants';

import { Loader } from '../loader';
import { DrawerLayout } from '../drawer-layout';
import { SecondaryBackButton } from '../back-button';
import { convertToProductFormData, ProductFormData } from '../product-add-view';
import { ProductSettingsSection } from '../product-settings-section';
import { StyledForm } from '../titled-form-layout';

export type ProductEditableBase = Pick<
  IProductDTO,
  'imageUrls' | 'name' | 'price' | 'status' | 'unit' | 'description'
>;

export interface IProductEditViewProps {
  product: IProductDTO;
  onClose?: () => void;
}

export function ProductEditView({ product, onClose }: IProductEditViewProps) {
  const dispatch = useAppDispatch();

  const isUpdating = useAppSelector(selectIsUserProductsResourceUpdating);

  const handleOnDelete = useCallback(
    () => dispatch(deleteProduct(product.id)).then(onClose),
    [dispatch, product.id, onClose],
  );

  const handleOnSubmit = (
    data: ProductFormData,
    { resetForm }: { resetForm: (props: { values: ProductFormData }) => void },
  ) => {
    dispatch(
      updateProduct({
        ...data,
        id: product.id,
      }),
    ).then((result) => {
      if ('error' in result) return;

      resetForm({
        values: convertToProductFormData(result.payload),
      });
    });
  };

  const onActivateToggle = () => {
    dispatch(
      toggleProductStatus({
        id: product.id,
        status:
          product.status === ProductStatus.Active
            ? ProductStatus.Deactivated
            : ProductStatus.Active,
      }),
    ).then((result) => {
      if ('error' in result) return;

      convertToProductFormData(result.payload);
    });
  };

  return (
    <DrawerLayout>
      <Formik
        onSubmit={handleOnSubmit}
        validationSchema={productSchemaValidator}
        initialValues={convertToProductFormData(product)}
      >
        {({ errors, dirty, setErrors, submitForm }) => {
          const isDisabled =
            isUpdating || Object.keys(errors || {}).length > 0 || !dirty;

          return (
            <>
              <DrawerLayout.Header
                sx={{
                  color: 'background.paper',
                  backgroundColor: 'primary.main',
                }}
                headerTitle="Редагувати оголошення"
              >
                <SecondaryBackButton
                  sx={{ borderRadius: 1 }}
                  onClick={onClose}
                />
              </DrawerLayout.Header>

              <DrawerLayout.Content
                sx={{
                  backgroundColor: 'background.default',
                }}
              >
                <StyledForm
                  sx={{
                    p: 2,
                    ['& > *:not(:last-child)']: {
                      mb: 2,
                    },
                    ['.MuiOutlinedInput-root']: {
                      backgroundColor: 'background.paper',
                      '&:hover': { backgroundColor: 'background.paper' },
                    },
                  }}
                >
                  <ProductEditableSection setErrors={setErrors} />
                  <ProductSettingsSection
                    isUpdating={isUpdating}
                    status={product.status}
                    onDelete={handleOnDelete}
                    onActivateToggle={onActivateToggle}
                  />
                </StyledForm>
              </DrawerLayout.Content>

              <DrawerLayout.Footer>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isDisabled}
                  onClick={submitForm}
                  sx={{ borderRadius: 24, fontWeight: 'bold', py: 2 }}
                  startIcon={
                    isUpdating ? (
                      <Loader size={24} color="inherit" />
                    ) : undefined
                  }
                >
                  {isUpdating ? 'Зберігаємо' : 'Зберегти'}
                </Button>
              </DrawerLayout.Footer>
            </>
          );
        }}
      </Formik>
    </DrawerLayout>
  );
}
