import React, { useCallback } from 'react';
import { useRouter } from 'next/router';

import { useAppSelector, useAppDispatch } from '@/store/app';
import {
  selectActiveShop,
  selectActiveShopProductsList,
  selectIsActiveUserShop,
  updateDraftProduct,
} from '@/store/domains';
import { ShopSidePanel } from '@/components/mui/shop-side-panel';
import { AppPublicLink } from '@/route/link';
import { selectShopViewTabQueryParam } from '@/store/services';
import { useLinkBuilder } from '@/hooks/use-link';
import { AppRoute } from '@/route/path';
import { useSameRouteNavigate } from '@/hooks/use-navigate';

export function ActiveShopPanel() {
  const router = useRouter();
  const navigate = useSameRouteNavigate();

  const dispatch = useAppDispatch();
  const isActiveUserShop = useAppSelector(selectIsActiveUserShop);

  const activeShop = useAppSelector(selectActiveShop);
  const activeShopProducts = useAppSelector(selectActiveShopProductsList);

  const { id: shopId } = activeShop || {};

  const activeTab = useAppSelector(selectShopViewTabQueryParam) || 'products';

  const onTabChange = useCallback(
    (shopTab: ShopViewTab) => navigate({ merge: { shopTab } }),
    [navigate],
  );

  const onProductAdd = useCallback(() => {
    dispatch(updateDraftProduct({}));
    navigate({ merge: { productTab: 'add-product' } });
  }, [navigate, dispatch]);

  const onClose = useCallback(
    () => navigate({ omit: ['shopId', 'shopTab'] }),
    [navigate],
  );

  const buildLink = useLinkBuilder();
  const getProductLink = useCallback(
    (productId: string) =>
      buildLink(router.route as AppRoute, {
        merge: { productId },
        omit: router.route === AppRoute.products ? ['shopId', 'shopTab'] : [],
      }),
    [router.route, buildLink],
  );

  return (
    <ShopSidePanel
      shop={activeShop}
      isUserShop={isActiveUserShop}
      shopLink={AppPublicLink.shop(shopId!)}
      products={activeShopProducts}
      getProductLink={getProductLink}
      onClose={onClose}
      activeTab={activeTab}
      onTabChange={onTabChange}
      onProductAdd={onProductAdd}
    />
  );
}
