import React, { useMemo } from 'react';

import { useNotification } from '@/hooks/use-notifications';

import {
  ActionsMenuButton,
  IMenuAction,
  useCopyToClipboardAction,
} from '../actions-menu-button';

const copyShopLinkActionText = 'Копіювати посилання на лавку';
const shopLinkCopiedMessageText = 'Посилання успішно скопійовано!';

const complainActionText = (shopName: string) => `Поскаржитись на ${shopName}`;
const complainFormHref = 'https://forms.gle/2dJhEf5hFWW1uRRu8';

export interface IShopActionsMenuButtonProps {
  shopLink?: string;
  shopName: string;
}

export function ShopActionsMenuButton({
  shopLink,
  shopName,
}: IShopActionsMenuButtonProps) {
  const showLinkCopiedNotification = useNotification(shopLinkCopiedMessageText);

  const copyLinkAction = useCopyToClipboardAction(
    copyShopLinkActionText,
    shopLink,
    showLinkCopiedNotification,
  );

  const actions = useMemo<IMenuAction[]>(
    () => [
      copyLinkAction,
      {
        text: complainActionText(shopName),
        href: complainFormHref,
      },
    ],
    [copyLinkAction, shopName],
  );

  return (
    <ActionsMenuButton
      actions={actions}
      sx={{
        color: 'background.paper',
        backgroundColor: 'secondary.main',
        ['&:hover']: {
          backgroundColor: 'secondary.main',
        },
      }}
      iconProps={{ fontSize: 'medium' }}
      menuProps={{
        anchorOrigin: { vertical: 'top', horizontal: 'left' },
        transformOrigin: { vertical: 'top', horizontal: 'right' },
      }}
    />
  );
}
