import React from 'react';
import Image from 'next/image';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

import { ProductStatus } from '@/utils/constants';

import { ProductPrice } from '../product-price';

export interface IProductCardProps {
  unit: Unit;
  name: string;
  price: number;
  width?: number;
  imageUrl?: string;
  status: ProductStatus;
}

interface ICardProps extends CardProps {
  width?: number;
  status: ProductStatus;
}

const ProductCardBox = styled(Card)<ICardProps>(({ theme, width, status }) => ({
  width,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  ...(status === ProductStatus.Deactivated && { filter: 'opacity(60%)' }),
  transition: theme.transitions.create(['transform', 'filter', 'box-shadow'], {
    duration: theme.transitions.duration.short,
    easing: theme.transitions.easing.easeInOut,
  }),
  '&:hover': {
    ...(status === ProductStatus.Deactivated && { filter: 'opacity(100%)' }),
    transform: 'translateY(-1px)',
    boxShadow: 4,
  },
}));

export function ProductCard({
  name,
  imageUrl,
  unit,
  price,
  status,
  width = 158,
}: IProductCardProps) {
  return (
    <ProductCardBox
      width={width}
      status={status}
      sx={{
        boxShadow: 0,
      }}
    >
      <ProductThumbnail imageUrl={imageUrl} width={width} height={width} />
      <CardContent sx={{ p: 1.5, pb: 0.7 }}>
        <Typography
          variant="body2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {name}
        </Typography>
      </CardContent>
      <CardActions sx={{ px: 1.5, pt: 0, mt: 'auto' }}>
        <ProductPrice price={price} unit={unit} />
      </CardActions>
    </ProductCardBox>
  );
}

interface IProductThumbnailProps {
  imageUrl?: string;
  width: number;
  height: number;
}

const productNoPhotoPlaceholderText = 'без фото';

function ProductThumbnail({ imageUrl, ...size }: IProductThumbnailProps) {
  if (!imageUrl) {
    return (
      <Box
        sx={{
          ...size,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'whitesmoke',
        }}
      >
        <Typography variant="caption" color="text.secondary" align="center">
          {productNoPhotoPlaceholderText}
        </Typography>
      </Box>
    );
  }

  return <Image {...size} src={imageUrl} objectFit="contain" priority alt="" />;
}
