import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

export type IFooterBoxProps = BoxProps & {
  footerType?: 'flat' | 'regular';
};

const FooterBox = styled(Box)<IFooterBoxProps>(
  ({ theme, footerType = 'regular' }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'background.paper',
    ...(footerType === 'regular'
      ? {
          paddingTop: theme.spacing(2),
          paddingRight: theme.spacing(3),
          paddingBottom: theme.spacing(2),
          paddingLeft: theme.spacing(3),
        }
      : {}),
  }),
);

export const Footer = ({ children, ...props }: IFooterBoxProps) => (
  <FooterBox {...props}>{children}</FooterBox>
);
