import React from 'react';
import Typography from '@mui/material/Typography';

export interface IProductPriceProps {
  price: number;
  unit: Unit;
}

const getPriceUnitText = (unit: Unit, currency = 'грн') => {
  switch (unit) {
    case 'KG':
      return `${currency}/кг`;
    case 'L':
      return `${currency}/літр`;
    default:
      return `${currency}/шт`;
  }
};

export function ProductPrice({ price, unit }: IProductPriceProps) {
  return (
    <Typography variant="body2" sx={{ fontWeight: 600 }}>
      {price}{' '}
      <Typography variant="caption" color="text.secondary">
        {getPriceUnitText(unit)}
      </Typography>
    </Typography>
  );
}
