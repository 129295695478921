import React, { useCallback } from 'react';

import { useAppSelector } from '@/store/app';
import { selectActiveProduct, selectActiveUserProduct } from '@/store/domains';
import { selectProductViewTabQueryParam } from '@/store/services';
import { ProductSidePanel } from '@/components/mui/product-side-panel';
import { AppPublicLink } from '@/route/link';
import { useSameRouteLink } from '@/hooks/use-link';
import { useSameRouteNavigate } from '@/hooks/use-navigate';

export function ActiveProductPanel() {
  const navigate = useSameRouteNavigate();

  const activeProduct = useAppSelector(selectActiveProduct);
  const activeUserProduct = useAppSelector(selectActiveUserProduct);

  const product = activeUserProduct || activeProduct;

  const { id, shopId } = product || {};

  const onClose = useCallback(
    () => navigate({ omit: ['productId', 'productTab'] }),
    [navigate],
  );

  const activeTab = useAppSelector(selectProductViewTabQueryParam) || 'product';
  const onTabChange = useCallback(
    (productTab: ProductViewTab) =>
      navigate({ merge: { productId: id, productTab } }),
    [navigate, id],
  );

  const editLink = useSameRouteLink({
    merge: {
      shopId,
      productId: id,
      productTab: 'edit-product',
    },
  });

  const contactsLink = useSameRouteLink({
    merge: { shopId, shopTab: 'contacts' },
    omit: ['productId', 'productTab'],
  });

  return (
    <ProductSidePanel
      product={product}
      productLink={AppPublicLink.product(shopId!, id!)}
      onClose={onClose}
      activeTab={activeTab}
      onTabChange={onTabChange}
      isUserProduct={Boolean(activeUserProduct)}
      editLink={editLink}
      contactsLink={contactsLink}
    />
  );
}
