import React, { useRef, useEffect } from 'react';
import { useField, FieldArray } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Box, { BoxProps } from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import Button, { ButtonTypeMap } from '@mui/material/Button';

import { ShopPhotoList } from '@/components/mui/shop-photo-list';
import { isBrowser } from '@/utils/is-browser';
import { readFileAsDataUrl } from '@/utils/file';

export interface IImagesFieldProps {
  label: string;
  name: string;
  type?: 'imagesPicker';
  props?: BoxProps;
  helperText?: string;
  buttonProps?: ButtonTypeMap['props'];
}

export function ImagesField({
  name,
  props,
  label,
  helperText,
  buttonProps,
}: IImagesFieldProps) {
  const [field, { error }] = useField<Array<{ src: string; file: File }>>(name);

  const renderIcon = (removeFn: () => void) => {
    return (
      <IconButton
        color="error"
        onClick={removeFn}
        sx={{
          top: -7,
          zIndex: 1,
          right: -7,
          padding: '0px',
          position: 'absolute',
          backgroundColor: 'background.paper',
          '&:hover': { backgroundColor: 'background.paper' },
        }}
      >
        <CloseIcon />
      </IconButton>
    );
  };

  const handleOnChange =
    (push: (...newItems: Array<{ src: string }>) => void) =>
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { files },
      } = event;

      if (files) {
        try {
          const data = await Promise.all(
            Array.from(files).map(async (file) => ({
              file,
              base64: await readFileAsDataUrl(file),
            })),
          );

          push(...data.map((item) => ({ src: item.base64, file: item.file })));
        } catch {
          // ignore
        }
      }

      event.target.value = '';
    };

  const inputRef = useRef<HTMLInputElement>(null);
  useFacebookAppUploadImageFix(inputRef);

  return (
    <FieldArray name={name}>
      {({ push, remove }) => (
        <>
          {field.value.length > 0 && (
            <ShopPhotoList
              photoUrls={field.value.map(({ src }, index) => ({
                icon: renderIcon(() => remove(index)),
                key: index,
                src,
              }))}
              boxProps={{
                sx: {
                  marginBottom: '0 !important',
                  paddingLeft: 0,
                },
              }}
            />
          )}
          <Box {...props}>
            <Button
              component="label"
              {...buttonProps}
              sx={{ ...(error && { color: 'red !important' }) }}
            >
              {label}
              <input
                hidden
                multiple
                type="file"
                onChange={handleOnChange(push)}
                accept="image/jpg,image/jpeg,image/png"
                ref={inputRef}
              />
            </Button>
            {helperText ? (
              <FormHelperText
                sx={{
                  mt: 0.5,
                  mx: 1.75,
                  ...(error && { color: 'red !important' }),
                }}
              >
                {helperText}
              </FormHelperText>
            ) : null}
          </Box>
        </>
      )}
    </FieldArray>
  );
}

const isFacebookApp = () => {
  if (!isBrowser) return false;

  const userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  return (
    userAgent.indexOf('FBAN') > -1 ||
    userAgent.indexOf('FBAV') > -1 ||
    userAgent.indexOf('Instagram') > -1
  );
};

const useFacebookAppUploadImageFix = (
  inputRef: React.RefObject<HTMLInputElement | null>,
) => {
  useEffect(() => {
    if (isFacebookApp()) {
      inputRef.current?.removeAttribute('accept');
    }
  }, [inputRef]);
};
