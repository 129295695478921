import { useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import Fab, { FabProps } from '@mui/material/Fab';

export interface IAddProductActionButtonProps {
  Icon: React.ComponentType<any>;
  variant: FabProps['variant'];
  color: FabProps['color'];
}

interface IAddProductButtonProps extends Omit<FabProps, 'onClick'> {
  label?: string;
  onClick?(): void;
  location?: 'map' | 'sidebar';
  getButtonProps?(): IAddProductActionButtonProps | undefined;
}

const StyledFab = styled(Fab)<IAddProductButtonProps>(
  ({ theme, location = 'sidebar' }) => ({
    fontWeight: 'bold',
    position: 'absolute',
    textTransform: 'uppercase',
    boxShadow: '0px 1px 18px rgba(0, 0, 0, 0.3)',
    ...(location === 'sidebar' && {
      right: 0,
      bottom: 47,
    }),
    ...(location === 'map' && {
      top: 0,
      right: 0,
      zIndex: 2,
      bottom: 'unset',
    }),
    margin: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2),
    },
  }),
);

export function AddProductButton({
  label,
  onClick,
  location,
  getButtonProps,
}: IAddProductButtonProps) {
  const actionButton = useMemo(() => {
    const {
      Icon = AddIcon,
      color = 'primary',
      variant = label ? 'extended' : 'circular',
    } = getButtonProps?.() ?? {};
    const styles = label ? { mr: 1 } : {};

    return (
      <StyledFab
        color={color}
        variant={variant}
        onClick={onClick}
        location={location}
      >
        <Icon sx={styles} />
        {label}
      </StyledFab>
    );
  }, [label, getButtonProps, onClick, location]);

  return actionButton;
}
