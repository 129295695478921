import React from 'react';
import Image from 'next/image';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import { styled, useTheme, Theme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export interface IFullScreenGalleryProps {
  open: boolean;
  onClose?: () => void;
  imageUrls?: string[];
  imageIndex?: number;
}

const renderIndicator = () => null;

const navButtonSx = (theme: Theme) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: 120,
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
});

export function FullScreenGallery({
  open,
  onClose,
  imageUrls,
  imageIndex = 0,
}: IFullScreenGalleryProps) {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{ sx: { backgroundColor: 'rgba(33, 33, 33, 0.9)' } }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          pt: '56px',
          ['& > div']: {
            width: '100%',
          },
          ['& .carousel-status']: {
            display: 'none',
          },
        }}
      >
        <ButtonControl
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: 120,
            height: 120,
            zIndex: 2,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </ButtonControl>
        <Carousel
          showThumbs={false}
          selectedItem={imageIndex}
          renderIndicator={renderIndicator}
          renderArrowPrev={(clickHandler, hasNext) =>
            hasNext ? (
              <ButtonControl
                sx={{
                  ...navButtonSx(theme),
                  left: 0,
                }}
                onClick={clickHandler}
              >
                <NavigateBeforeIcon />
              </ButtonControl>
            ) : null
          }
          renderArrowNext={(clickHandler, hasNext) =>
            hasNext ? (
              <ButtonControl
                sx={{
                  ...navButtonSx(theme),
                  right: 0,
                }}
                onClick={clickHandler}
              >
                <NavigateNextIcon />
              </ButtonControl>
            ) : null
          }
        >
          {imageUrls?.map((imageUrl) => (
            <Box
              key={imageUrl}
              sx={{
                width: '100%',
                height: '88vh',
                position: 'relative',
                boxSizing: 'border-box',
              }}
            >
              <Image layout="fill" objectFit="contain" src={imageUrl} alt="" />
            </Box>
          ))}
        </Carousel>
      </Box>
    </Modal>
  );
}

const ButtonControl = styled(ButtonBase)<ButtonBaseProps>(({ theme }) => ({
  color: theme.palette.background.paper,
  backgroundColor: 'transparent',
  ['&:hover']: {
    backgroundColor: 'rgba(0, 0, 0, 0.42)',
  },
}));
