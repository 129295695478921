import React, { useEffect, useMemo, useState } from 'react';
import Clipboard from 'clipboard';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import type { SvgIconTypeMap } from '@mui/material';

import { iOSMobileMenuTransitionDurationFix } from '@/utils/iOS';
import { isBrowser } from '@/utils/is-browser';

export interface IMenuAction {
  setRef?: (menuItem: HTMLElement) => void;
  text: string;
  href?: string;
  onAction?: () => void;
}

export interface IActionsMenuButtonProps extends IconButtonProps {
  actions: IMenuAction[];
  menuProps?: Partial<MenuProps>;
  iconProps?: SvgIconTypeMap['props'];
}

export function ActionsMenuButton({
  actions,
  menuProps,
  iconProps,
  ...props
}: IActionsMenuButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const onButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    !anchorEl ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="small"
        color="secondary"
        {...props}
        sx={{
          borderRadius: 1,
          ...props.sx,
        }}
        onClick={onButtonClick}
      >
        <MoreVertIcon fontSize="medium" {...iconProps} />
      </IconButton>
      <Menu
        {...menuProps}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        transitionDuration={iOSMobileMenuTransitionDurationFix}
      >
        {actions.map(({ text, href, onAction, setRef }, index) => {
          const anchorProps = href
            ? {
                href,
                component: 'a',
                target: '_blank',
                referrerPolicy: 'no-referrer',
              }
            : {};

          return (
            <MenuItem
              key={index}
              ref={setRef as any}
              onClick={() => {
                onAction?.();
                onClose();
              }}
              {...anchorProps}
            >
              {text}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export const useCopyToClipboardAction = (
  text: string,
  textToCopy = text,
  onCopied?: () => void,
): IMenuAction => {
  const [trigger, setTrigger] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!isBrowser || !trigger) return;

    const clipboard = new Clipboard(trigger, {
      text: () => textToCopy,
      container: trigger.parentElement!,
    });
    clipboard.on('success', () => onCopied?.());

    return () => clipboard.destroy();
  }, [trigger, onCopied, textToCopy]);

  return useMemo(
    () => ({
      text,
      setRef: setTrigger,
    }),
    [text],
  );
};
