import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

export interface IHeaderActionsBox extends BoxProps {
  type: 'filled' | 'floating';
  children: React.ReactNode;
}

export const HeaderActionsBox = styled(Box)<IHeaderActionsBox>(
  ({ theme, type }) => ({
    padding: theme.spacing(2),
    minHeight: 90,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background:
      type === 'filled'
        ? 'linear-gradient(to right, rgba(231, 250, 148, 1) 35%, rgba(102, 177, 255, 1) 100%)'
        : 'transparent',
    position: type === 'floating' ? 'absolute' : 'unset',
    top: 0,
    zIndex: 1,
  }),
);

HeaderActionsBox.displayName = 'HeaderActionsBox';
