import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import type { ProductStatus } from '@/utils/constants';

interface IProductSettingsSectionProps {
  onDelete(): void;
  isUpdating: boolean;
  status: ProductStatus;
  onActivateToggle(): void;
}

interface ISettingsData {
  buttonLabel: string;
  description: string;
  buttonIcon: React.ReactNode;
  buttonColor: 'warning' | 'success';
}

const settingsText: Record<ProductStatus, ISettingsData> = {
  active: {
    buttonColor: 'warning',
    buttonIcon: <VisibilityOff />,
    buttonLabel: 'Сховати оголошення з лавки',
    description:
      'Оголошення буде сховано з лавки та пошуку, ти завжди зможеш увімкнути його назад при потребі.',
  },
  deactivated: {
    buttonColor: 'success',
    buttonIcon: <Visibility />,
    buttonLabel: 'Активувати товар',
    description:
      'Оголошення буде показано у лавці та пошуку, ти завжди зможеш вимкнути його назад при потребі.',
  },
};

export function ProductSettingsSection({
  status,
  onDelete,
  isUpdating,
  onActivateToggle,
}: IProductSettingsSectionProps) {
  const { buttonLabel, description, buttonColor } = useMemo(
    () => settingsText[status],
    [status],
  );

  const shopDeactivateButton = useMemo(() => {
    return (
      <Button
        sx={{ p: 1 }}
        color={buttonColor}
        disabled={isUpdating}
        onClick={onActivateToggle}
      >
        {buttonLabel}
      </Button>
    );
  }, [buttonColor, buttonLabel, isUpdating, onActivateToggle]);

  return (
    <Box>
      <Divider orientation="horizontal" sx={{ width: '100%', mb: 2 }} />
      {shopDeactivateButton}
      <FormHelperText sx={{ mx: 1 }}>
        {description}
        <br />
        <br />
        <Button
          variant="text"
          disabled={isUpdating}
          onClick={onDelete}
          sx={{
            p: 0,
            typography: 'caption',
            color: 'inherit',
            textTransform: 'none',
            textDecoration: 'underline',
          }}
        >
          Видалити це оголошення назавжди
        </Button>
      </FormHelperText>
    </Box>
  );
}
