import { useCallback } from 'react';

import { useAppDispatch } from '@/store/app';
import {
  addNotification,
  hideNotification,
  INotificationData,
} from '@/store/domains';

export const useNotifications = () => {
  const dispatch = useAppDispatch();

  const show = useCallback(
    (notification: INotificationData) =>
      dispatch(addNotification(notification)),
    [dispatch],
  );
  const hide = useCallback(() => dispatch(hideNotification()), [dispatch]);

  return { show, hide };
};

export const useNotification = (message: string) => {
  const { show } = useNotifications();
  return useCallback(() => show({ message }), [message, show]);
};
