import { isBrowser } from '@/utils/is-browser';

export const AppPublicLink = {
  shop: (shopId: string) => {
    const shopPath = `/shops?shopId=${shopId}`;
    return isBrowser ? `${window.location.origin}${shopPath}` : shopPath;
  },
  product: (shopId: string, productId: string) => {
    const productPath = `/shops?shopId=${shopId}&productId=${productId}`;
    return isBrowser ? `${window.location.origin}${productPath}` : productPath;
  },
};
