import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import type { SvgIconTypeMap } from '@mui/material';

export interface ICloseButtonProps extends IconButtonProps {
  iconProps?: SvgIconTypeMap['props'];
}

export function CloseButton({ iconProps, ...props }: ICloseButtonProps) {
  return (
    <IconButton
      size="small"
      color="secondary"
      {...props}
      sx={{
        borderRadius: 1,
        ...props.sx,
      }}
    >
      <CloseIcon {...iconProps} />
    </IconButton>
  );
}

export function SecondaryCloseButton(props: ICloseButtonProps) {
  return (
    <CloseButton
      {...props}
      sx={{
        color: 'background.paper',
        backgroundColor: 'secondary.main',
        ['&:hover']: {
          backgroundColor: 'secondary.main',
        },
        ...props.sx,
      }}
      iconProps={{ fontSize: 'medium', ...props.iconProps }}
    />
  );
}
