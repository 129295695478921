import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useField } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';

import { getAddressDataFromFormattedAddress } from '@/hooks/use-geocode';

import {
  AddressValue,
  AddressBounds,
  MuiAddressInputField,
  IMuiAddressInputFieldProps,
  IMuiAddressInputFieldImperativeHandle,
} from '../mui/address-input-field';
import { Loader } from '../mui/loader';

export interface IAddressInputFieldProps {
  name: string;
  inputType?: 'addressInput';
  defaultValue?: AddressValue;
  cityProps?: Partial<IMuiAddressInputFieldProps>;
  streetProps?: Partial<IMuiAddressInputFieldProps>;
}

export function AddressInputField({
  name,
  cityProps,
  streetProps,
}: IAddressInputFieldProps) {
  const [field, { error }, { setValue }] = useField(name);

  const [isLoading, setIsLoading] = useState(true);
  const [cityAddress, setCityAddress] = useState<AddressValue | undefined>();
  const [cityBounds, setCityBounds] = useState<AddressBounds | undefined>();

  const cityInputRef = useRef<IMuiAddressInputFieldImperativeHandle>(null);
  const streetInputRef = useRef<IMuiAddressInputFieldImperativeHandle>(null);

  useEffect(() => {
    const value = field.value as AddressValue;
    if (value.formattedAddress) {
      setIsLoading(true);
      getAddressDataFromFormattedAddress(value.formattedAddress)
        .then(({ city, streetAddress, bounds }) => {
          setCityBounds(bounds);
          if (cityInputRef.current) {
            cityInputRef.current.setInputValue(city);
          }
          if (streetInputRef.current) {
            streetAddress &&
              streetInputRef.current.setInputValue(streetAddress);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCity: IMuiAddressInputFieldProps['onChange'] = (
    address,
    bounds,
  ) => {
    setCityBounds(bounds);
    setCityAddress(address);
    setValue(address);
    streetInputRef.current?.clearInputValue();
  };

  const onClearCity: IMuiAddressInputFieldProps['onClear'] = () => {
    setCityAddress(undefined);
    setCityBounds(undefined);
    setValue(undefined);
    streetInputRef.current?.clearInputValue();
  };

  const onChangeStreet: IMuiAddressInputFieldProps['onChange'] = (address) => {
    setValue(address);
  };

  const onClearStreet: IMuiAddressInputFieldProps['onClear'] = () => {
    setValue(cityAddress);
  };

  const loadingIndicator = useMemo(
    () =>
      isLoading ? (
        <InputAdornment position="start">
          <Loader size={20} color="primary" />
        </InputAdornment>
      ) : null,
    [isLoading],
  );

  return (
    <>
      <MuiAddressInputField
        type="city"
        ref={cityInputRef}
        disabled={isLoading}
        onBlur={field.onBlur}
        onClear={onClearCity}
        onChange={onChangeCity}
        error={!!error}
        helperText={error}
        {...cityProps}
        InputProps={{
          startAdornment: loadingIndicator,
          ...cityProps?.InputProps,
        }}
      />
      <MuiAddressInputField
        type="street"
        bounds={cityBounds}
        disabled={isLoading}
        ref={streetInputRef}
        onBlur={field.onBlur}
        onClear={onClearStreet}
        onChange={onChangeStreet}
        {...streetProps}
        InputProps={{
          startAdornment: loadingIndicator,
          ...cityProps?.InputProps,
        }}
      />
    </>
  );
}
