import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Link from 'next/link';

import { HeaderActionsBox } from '../header-actions-box';
import { SecondaryBackButton } from '../back-button';
import { ProductPhotoGallery } from '../product-photo-gallery';
import { ProductPrice } from '../product-price';
import { ProductActionsMenuButton } from '../product-actions-menu-button';
import { UserProductFooter } from '../user-product-footer';

const contactsButtonText = 'контакти';

const ONE_MINUTE = 60 * 1_000;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;
const ONE_WEEK = 7 * ONE_DAY;
const ONE_MONTH = 4 * ONE_WEEK;
const ONE_YEAR = 12 * ONE_MONTH;

export interface IProductViewProps {
  product: IProductDTO;
  productLink: string;
  onClose?: () => void;
  isUserProduct: boolean;
  editLink?: string;
  contactsLink: string;
}

export function ProductView({
  product,
  onClose,
  editLink,
  productLink,
  contactsLink,
  isUserProduct,
}: IProductViewProps) {
  const result = useMemo(() => {
    const formatter = new Intl.RelativeTimeFormat('uk-UA');

    if (product.updatedAt) {
      const diff = new Date(product.updatedAt).getTime() - new Date().getTime();
      const absDiff = Math.abs(diff);

      if (absDiff / ONE_YEAR >= 1) {
        return formatter.format(Math.ceil(diff / ONE_YEAR), 'year');
      } else if (absDiff / ONE_MONTH >= 1) {
        return formatter.format(Math.ceil(diff / ONE_MONTH), 'month');
      } else if (absDiff / ONE_WEEK >= 1) {
        return formatter.format(Math.ceil(diff / ONE_WEEK), 'week');
      } else if (absDiff / ONE_DAY >= 1) {
        return formatter.format(Math.ceil(diff / ONE_DAY), 'day');
      } else if (absDiff / ONE_HOUR >= 1) {
        return formatter.format(Math.ceil(diff / ONE_DAY), 'hour');
      } else if (absDiff / ONE_MINUTE >= 1) {
        return formatter.format(Math.ceil(diff / ONE_MINUTE), 'minute');
      }

      return 'тільки що';
    }

    return 'місяць тому';
  }, [product.updatedAt]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <HeaderActionsBox
        type={product.imageUrls.length > 0 ? 'floating' : 'filled'}
      >
        <SecondaryBackButton onClick={onClose} />
        <ProductActionsMenuButton productLink={productLink} />
      </HeaderActionsBox>
      {product.imageUrls.length > 0 && (
        <ProductPhotoGallery photoUrls={product.imageUrls} />
      )}
      <Box p={2} sx={{ overflow: 'auto' }}>
        <Typography variant="h6">{product.name}</Typography>
        <Typography variant="body2" mt={1.5}>
          {product.description}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="caption" color="text.secondary">
          Дата створення оголошення: {result}
        </Typography>
      </Box>
      <Divider sx={{ mt: 'auto' }} />
      <Box
        sx={{
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <ProductPrice {...product} />
        <Link href={contactsLink} passHref shallow>
          <Button variant="contained" size="large" sx={{ borderRadius: 6 }}>
            {contactsButtonText}
          </Button>
        </Link>
      </Box>
      {isUserProduct && editLink ? <UserProductFooter href={editLink} /> : null}
    </Box>
  );
}
