import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useField } from 'formik';

export interface INumberFieldProps {
  name: string;
  type?: 'number';
  props?: TextFieldProps;
}

const convertFull = (value: string) => {
  const numberValue = Number(value);

  if (numberValue < 0) {
    return 0;
  }

  return numberValue;
};

export function NumberField({
  name,
  props: { helperText, ...props } = {},
}: INumberFieldProps) {
  const [field, { error }, { setValue }] = useField(name);

  const customOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    if (!value || Number.isNaN(Number(value))) {
      event.target.value = '0';
      setValue(0);
      return;
    }
    if (value.includes('.') || value.includes(',')) {
      event.target.value = value.replace(/\.|,/gi, '');
      setValue(parseFloat(event.target.value));
      return;
    }

    const newValue = convertFull(value);
    event.target.value = newValue.toString();
    setValue(newValue);
  };

  return (
    <TextField
      {...field}
      {...props}
      onChange={customOnChange}
      error={Boolean(error)}
      helperText={error || helperText}
    />
  );
}
