import Button from '@mui/material/Button';
import React from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useAppDispatch, useAppSelector } from '@/store/app';
import {
  deleteShop,
  updateShop,
  toggleShopStatus,
  selectIsUserShopUpdating,
} from '@/store/domains';
import type { IAddressAutoCompleteValue } from '@/hooks/use-address-auto-complete';
import { shopSchemaValidator } from '@/utils/validators';
import { ShopStatus } from '@/utils/constants';

import { Loader } from '../loader';
import { DrawerLayout } from '../drawer-layout';
import { ShopEditableSection } from '../shop-editable-section';
import { ShopSettingsSection } from '../shop-settings-section';
import { StyledForm } from '../titled-form-layout';

export interface IShopEditViewProps {
  shop: IShopDTO;
  onClose?: () => void;
  children?: React.ReactNode;
  closeButton?: React.ReactNode;
}

export type ShopFormData = Omit<IShopCreateRequest, 'imageUrls' | 'address'> & {
  imageUrls: Array<ICreateImageDTO>;
  address: IAddressAutoCompleteValue;
  phoneNumber: string;
};

const transformToFormData = (shop: IShopDTO): ShopFormData => {
  return {
    ...shop,
    phoneNumber: shop.phoneNumber.slice(3),
    address: {
      placeId: '',
      coordinates: shop.coordinates,
      formattedAddress: shop.address,
    },
    imageUrls: shop.imageUrls.map((src) => ({ src })),
  };
};

export function ShopEditView({
  shop,
  onClose,
  closeButton,
}: IShopEditViewProps) {
  const dispatch = useAppDispatch();
  const isUpdating = useAppSelector(selectIsUserShopUpdating);

  const handleOnSubmit = (
    data: ShopFormData,
    { resetForm }: FormikHelpers<ShopFormData>,
  ) => {
    dispatch(
      updateShop({
        ...data,
        id: shop.id,
      }),
    ).then((result) => {
      if ('error' in result) return;

      resetForm({
        values: transformToFormData(result.payload),
      });
    });
  };

  const onAccountDelete = () => {
    dispatch(deleteShop(shop.id)).then(onClose);
  };

  return (
    <DrawerLayout>
      <Formik
        onSubmit={handleOnSubmit}
        validationSchema={shopSchemaValidator}
        initialValues={transformToFormData(shop)}
      >
        {({ errors, dirty, submitForm, resetForm }) => {
          const isDisabled =
            isUpdating || Object.keys(errors || {}).length > 0 || !dirty;

          const onActivateToggle = () => {
            dispatch(
              toggleShopStatus({
                id: shop.id,
                status:
                  shop.status === ShopStatus.Deactivated
                    ? ShopStatus.Active
                    : ShopStatus.Deactivated,
              }),
            ).then((result) => {
              if ('error' in result) return;

              resetForm({
                values: transformToFormData(result.payload),
              });
            });
          };

          return (
            <>
              <DrawerLayout.Header
                headerTitle="Редагувати лавку"
                sx={{
                  backgroundImage: 'none',
                  backgroundColor: 'info.main',
                }}
              >
                {closeButton}
              </DrawerLayout.Header>
              <DrawerLayout.Content
                sx={{
                  backgroundColor: 'background.default',
                }}
              >
                <StyledForm
                  sx={{
                    p: 2,
                    ['& > *:not(:last-child)']: {
                      mb: 2,
                    },
                    ['.MuiOutlinedInput-root']: {
                      backgroundColor: 'background.paper',
                      '&:hover': { backgroundColor: 'background.paper' },
                    },
                  }}
                >
                  <ShopEditableSection />
                  <ShopSettingsSection
                    status={shop.status}
                    isUpdating={isUpdating}
                    onAccountDelete={onAccountDelete}
                    onActivateToggle={onActivateToggle}
                  />
                </StyledForm>
              </DrawerLayout.Content>
              <DrawerLayout.Footer>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={submitForm}
                  startIcon={
                    isUpdating ? (
                      <Loader size={24} color="inherit" />
                    ) : undefined
                  }
                  disabled={isDisabled}
                  sx={{ borderRadius: 24, fontWeight: 'bold', py: 2 }}
                >
                  {isUpdating ? 'Зберігаємо' : 'Зберегти'}
                </Button>
              </DrawerLayout.Footer>
            </>
          );
        }}
      </Formik>
    </DrawerLayout>
  );
}
