import * as yup from 'yup';

export const productSchemaValidator = yup.object().shape({
  name: yup
    .string()
    .required('Введіть назву товару')
    .min(2, 'Назва повинна бути не менше 2 символів'),
  price: yup
    .number()
    .min(1, 'Мінімальна ціна 1 грн')
    .required('Введіть ціну товару'),
  description: yup
    .string()
    .required('Введіть опис товару')
    .min(2, 'Опис повинна бути не менше 2 символів'),
  unit: yup.string().required('Оберіть одиницю виміру'),
  categoryId: yup.string().uuid().required('Оберіть категорію'),
  imageUrls: yup.array().min(1, 'At least one image is required'),
});

export const shopSchemaValidator = yup.object().shape({
  name: yup
    .string()
    .required('Введіть назву лавки')
    .min(2, 'Назва повинна бути не менше 2 символів'),
  phoneNumber: yup.string().matches(/^\d{9}$/, 'Невірний формат номеру'),
});
