import React from 'react';
import Link from 'next/link';
import Button, { ButtonProps } from '@mui/material/Button';

export interface ILinkButtonProps extends ButtonProps {
  as?: string;
  href: string;
}

export function LinkButton({ as, href, ...props }: ILinkButtonProps) {
  return (
    <Link as={as} href={href} passHref>
      <Button {...props} />
    </Link>
  );
}
