import React, { useMemo } from 'react';

import { useNotification } from '@/hooks/use-notifications';

import {
  ActionsMenuButton,
  IMenuAction,
  useCopyToClipboardAction,
} from '../actions-menu-button';

const copyProductLinkActionText = 'Копіювати посилання на оголошення';
const productLinkCopiedMessageText = 'Посилання успішно скопійовано!';

const complainActionText = 'Поскаржитись на оголошення';
const complainFormHref = 'https://forms.gle/2dJhEf5hFWW1uRRu8';

export interface IProductActionsMenuButtonProps {
  productLink?: string;
}

export function ProductActionsMenuButton({
  productLink,
}: IProductActionsMenuButtonProps) {
  const showLinkCopiedNotification = useNotification(
    productLinkCopiedMessageText,
  );

  const copyLinkAction = useCopyToClipboardAction(
    copyProductLinkActionText,
    productLink,
    showLinkCopiedNotification,
  );

  const actions = useMemo<IMenuAction[]>(
    () => [
      copyLinkAction,
      {
        text: complainActionText,
        href: complainFormHref,
      },
    ],
    [copyLinkAction],
  );

  return (
    <ActionsMenuButton
      actions={actions}
      sx={{
        color: 'background.paper',
        backgroundColor: 'secondary.main',
        ['&:hover']: {
          backgroundColor: 'secondary.main',
        },
      }}
      iconProps={{ fontSize: 'medium' }}
      menuProps={{
        anchorOrigin: { vertical: 'top', horizontal: 'left' },
        transformOrigin: { vertical: 'top', horizontal: 'right' },
      }}
    />
  );
}
