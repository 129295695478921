import AddIcon from '@mui/icons-material/Add';
import type { OutlinedTextFieldProps } from '@mui/material/TextField';

import {
  TextField,
  ImagesField,
  PhoneNumberField,
  AddressInputField,
} from '@/components/formik';
import {
  DEFAULT_PHONE_COUNTRY_CODE,
  DEFAULT_PHONE_NUMBER_LENGTH,
} from '@/utils/sanitize-phone-number';

interface IShopEditableSectionProps {
  countryCode?: string;
  phoneNumberLength?: number;
}

const defaultFieldProps: Omit<OutlinedTextFieldProps, 'onChange' | 'type'> = {
  variant: 'outlined',
  size: 'small',
};

export function ShopEditableSection({
  countryCode = DEFAULT_PHONE_COUNTRY_CODE,
  phoneNumberLength = DEFAULT_PHONE_NUMBER_LENGTH,
}: IShopEditableSectionProps) {
  return (
    <>
      <ImagesField
        name="imageUrls"
        label="Додати фотографії товару"
        helperText="Додай власне фото та фотографії свого чудового господарства."
        buttonProps={{
          variant: 'text',
          startIcon: <AddIcon />,
        }}
      />
      <TextField
        name="name"
        props={{
          label: 'Твоє ім’я',
          helperText:
            'Використовуй коротке ім’я, щоб покупці/продавці швидше знаходили тебе',
          ...defaultFieldProps,
        }}
      />
      <PhoneNumberField
        name="phoneNumber"
        countryCode={countryCode}
        phoneNumberLength={phoneNumberLength}
        props={{
          disabled: true,
          label: 'Номер телефону',
          helperText: 'Телефон для зв’язку з тобою',
          ...defaultFieldProps,
        }}
      />
      <AddressInputField
        name="address"
        cityProps={{
          label: 'Місто/селище',
          helperText: 'Вибери в якому місті буде розташовані твої оголошення',
          ...defaultFieldProps,
        }}
        streetProps={{
          label: 'Вулиця',
          helperText: 'Вулиця не є обов’язковою, можеш вказати тільки місто',
          ...defaultFieldProps,
        }}
      />
    </>
  );
}
