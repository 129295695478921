import React, { useCallback } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

const productsTabLabelText = 'товари';
const contactsTabLabelText = 'контакти';

const tabLabelMap: Partial<
  Record<ShopViewTab, (productsCount?: number) => React.ReactNode>
> = {
  products: (productsCount?: number) => (
    <Typography variant="button">
      {productsTabLabelText}{' '}
      {Number.isFinite(productsCount) && (
        <Typography
          variant="button"
          fontSize="inherit"
          lineHeight="inherit"
          color="text.secondary"
        >
          {productsCount}
        </Typography>
      )}
    </Typography>
  ),
  contacts: () => (
    <Typography variant="button">{contactsTabLabelText}</Typography>
  ),
};

export interface IShopViewTabsProps {
  activeTab?: ShopViewTab;
  onTabChange?: (tab: ShopViewTab) => void;
  productsCount?: number;
}

const TABS_ORDER: ShopViewTab[] = ['products', 'contacts'];

export function ShopViewTabs({
  activeTab = 'products',
  onTabChange,
  productsCount,
}: IShopViewTabsProps) {
  const onChange = useCallback(
    (_: React.SyntheticEvent, tab: number) => onTabChange?.(TABS_ORDER[tab]),
    [onTabChange],
  );

  return (
    <Tabs
      value={TABS_ORDER.indexOf(activeTab)}
      onChange={onChange}
      textColor="secondary"
      indicatorColor="secondary"
    >
      {TABS_ORDER.map((tab) => (
        <Tab
          key={tab}
          id={`${tab}-tab`}
          label={tabLabelMap?.[tab]?.(productsCount)}
          sx={{ width: '50%' }}
        />
      ))}
    </Tabs>
  );
}
