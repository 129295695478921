import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

const ContentBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  overflowY: 'scroll',
  flexDirection: 'column',
  backgroundColor: 'background.default',
});

export const Content = ({ children, ...props }: BoxProps) => (
  <ContentBox {...props}>{children}</ContentBox>
);
