import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import PhoneIcon from '@mui/icons-material/Phone';

import { useFacebookPixelContactPhoneClickTracker } from '@/hooks/use-facebook-pixel';
import { sanitizePhoneNumber } from '@/utils/sanitize-phone-number';

export interface IShopContactsViewProps {
  shopId: string;
  phone: string;
}

export function ShopContactsView({ shopId, phone }: IShopContactsViewProps) {
  const phoneNumber = formatPhoneNumber(phone);

  const trackPhoneClick = useFacebookPixelContactPhoneClickTracker(
    shopId,
    phone,
  );

  return (
    <List sx={{ pl: 2 }}>
      <ListItem disablePadding>
        <ListItemButton
          component="a"
          href={createPhoneNumberHref(phoneNumber)}
          onClick={trackPhoneClick}
        >
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary={phoneNumber} />
        </ListItemButton>
      </ListItem>
      <Divider />
    </List>
  );
}

const formatPhoneNumber = (phone: string) => `+${sanitizePhoneNumber(phone)}`;
const createPhoneNumberHref = (phone: string) => `tel:${phone}`;
