import React from 'react';
import Link from 'next/link';
import { styled, useTheme } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

import { ProductCard } from '../product-card';
import { Loader } from '../loader';

export interface IProductCardsListProps extends BoxProps {
  products?: IProductDTO[];
  getProductLink: (productId: string) => string;
  cardWidth?: number;
}

export function ProductCardsList({
  products,
  getProductLink,
  cardWidth = 158,
  ...props
}: IProductCardsListProps) {
  const theme = useTheme();

  if (!products) {
    return <Loader />;
  }

  return (
    <Box
      {...props}
      sx={{
        pt: 0,
        gap: 2,
        px: 1.25,
        display: 'grid',
        justifyItems: 'center',
        gridTemplateColumns: `repeat(auto-fill, minmax(${cardWidth}px, 1fr))`,
        [theme.breakpoints.down('sm')]: {
          columnGap: 1,
        },
        ...props.sx,
      }}
    >
      {products?.map(({ id, imageUrls, ...product }) => (
        <Link key={id} href={getProductLink(id)} passHref shallow>
          <LinkCard>
            <ProductCard
              {...product}
              width={cardWidth}
              imageUrl={imageUrls?.[0]}
            />
          </LinkCard>
        </Link>
      ))}
    </Box>
  );
}

const LinkCard = styled('a')({
  textDecoration: 'none',
  color: 'initial',
});
