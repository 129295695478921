import { useField } from 'formik';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';

export interface ITextFieldProps {
  name: string;

  type?: 'text';
  props?: MuiTextFieldProps;
}

export function TextField({ name, props }: ITextFieldProps) {
  const [field, { error }] = useField(name);

  return (
    <MuiTextField
      {...field}
      {...props}
      error={Boolean(error)}
      helperText={error || props?.helperText}
    />
  );
}
