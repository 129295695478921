import { useEffect } from 'react';
import { FormikErrors, useField } from 'formik';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import type { OutlinedTextFieldProps } from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

import {
  TextField,
  ImagesField,
  NumberField,
  SelectField,
} from '@/components/formik';
import { categoriesMap, unitToTranslationMap } from '@/utils/translations';
import { useAppSelector } from '@/store/app';
import { selectCategoriesList } from '@/store/domains';

import type { ProductFormData } from '../product-add-view';

interface IProductEditableSectionProps {
  setErrors: (errors: FormikErrors<ProductFormData>) => void;
}

const defaultFieldProps: OutlinedTextFieldProps = {
  size: 'small',
  fullWidth: true,
  variant: 'outlined',
};

export function ProductEditableSection({
  setErrors,
}: IProductEditableSectionProps) {
  const [imageUrlsField] = useField('imageUrls');
  const categories = useAppSelector(selectCategoriesList);

  useEffect(() => {
    if (imageUrlsField.value.length === 0) {
      setErrors?.({
        imageUrls: 'Додайте фото',
      });
    } else {
      setErrors({
        imageUrls: undefined,
      });
    }
  }, [imageUrlsField.value, setErrors]);

  return (
    <>
      <ImagesField
        name="imageUrls"
        label="Додати фотографії товару"
        helperText="Фото товару, а краще декілька"
        buttonProps={{
          variant: 'text',
          startIcon: <AddIcon />,
        }}
        props={{
          sx: {
            mb: 2,
            display: 'flex',
            alignSelf: 'flex-start',
            flexDirection: 'column',
          },
        }}
      />
      <TextField
        name="name"
        props={{
          label: 'Назва товару',
          helperText: 'Коротка назва товару. Наприклад: Мед акацієвий',
          ...defaultFieldProps,
        }}
      />
      <TextField
        name="description"
        props={{
          maxRows: 4,
          multiline: true,
          label: 'Опис товару',
          helperText:
            'Детальний опис товару, умови доставки та інша важлива інформація.',
          ...defaultFieldProps,
        }}
      />
      <SelectField
        name="categoryId"
        options={
          categories?.map(({ id, name }) => ({
            value: id,
            label: categoriesMap[name],
          })) ?? []
        }
        props={{
          label: 'Категорія',
          helperText: 'Вибери категорію товару із списку',
          ...defaultFieldProps,
          sx: {
            ...defaultFieldProps.sx,
          },
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <NumberField
          name="price"
          props={{
            label: 'Ціна',
            helperText: 'Ціна за одиницю виміру',
            ...defaultFieldProps,
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">грн.</InputAdornment>
              ),
              ...defaultFieldProps.InputProps,
            },
          }}
        />
        <SelectField
          name="unit"
          options={Object.entries(unitToTranslationMap).map(
            ([value, label]) => ({
              value,
              label,
            }),
          )}
          props={{
            ...defaultFieldProps,
            sx: {
              width: 108,
              ml: 2,
            },
          }}
        />
      </Box>
    </>
  );
}
