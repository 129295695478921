import { useMemo } from 'react';
import Typography from '@mui/material/Typography';

import { ShopStatus } from '@/utils/constants';

import {
  ProductCardsList,
  IProductCardsListProps,
} from '../product-cards-list';

export type IShopDeactivatedProductsProps = IProductCardsListProps;

export function ShopDeactivatedProducts({
  products,
  ...productsProps
}: IShopDeactivatedProductsProps) {
  const deactivatedProducts = useMemo(
    () =>
      products?.filter((product) => product.status === ShopStatus.Deactivated),
    [products],
  );

  if (!deactivatedProducts?.length) {
    return null;
  }

  return (
    <>
      <Typography color="text.secondary" variant="body2" sx={{ py: 2, px: 2 }}>
        Сховані товари (видно тільки тобі)
      </Typography>
      <ProductCardsList
        {...productsProps}
        products={deactivatedProducts}
        sx={{ pb: 2, columnGap: 1 }}
      />
    </>
  );
}
