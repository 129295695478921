import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { LinkButton } from '../link-button';

const FooterBox = styled(Box)(({ theme }) => ({
  fontSize: 14,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  color: theme.palette.background.paper,
  backgroundColor: theme.palette.text.primary,
}));

interface IUserProductFooterProps {
  href: string;
}

export function UserProductFooter(props: IUserProductFooterProps) {
  return (
    <FooterBox>
      <span>🍑 Це твоє оголошення</span>
      <LinkButton
        sx={{
          py: 0.5,
          px: 1,
          fontSize: 13,
          color: 'background.paper',
          textDecoration: 'underline',
        }}
        {...props}
      >
        Редагувати
      </LinkButton>
    </FooterBox>
  );
}
