import { CategoryName } from './constants';

export const categoriesMap: Record<CategoryType, string> = {
  [CategoryName.Vegetables]: 'Овочі',
  [CategoryName.Melons]: 'Баштанні',
  [CategoryName.Greens_Spices]: 'Зелень та прянощі',
  [CategoryName.Fruits]: 'Фрукти',
  [CategoryName.Berries]: 'Ягоди',
  [CategoryName.Nuts]: 'Горіхи',
  [CategoryName.Legumes]: 'Бобові',
  [CategoryName.Cereals_Grains]: 'Крупи та зерно',
  [CategoryName.Oil]: 'Олія та олійні культури',
  [CategoryName.AnimalMilk]: 'Молоко тваринне',
  [CategoryName.VegetableMilk]: 'Молоко рослинне',
  [CategoryName.Dairy]: 'Молочна продукція',
  [CategoryName.Cheese]: 'Сир',
  [CategoryName.Eggs]: 'Яйця столові',
  [CategoryName.Meat_Lard]: 'Мясо та сало',
  [CategoryName.MeatDelicacies]: 'М’ясні делікатеси',
  [CategoryName.BeeProducts]: 'Мед та продукти бджільництва',
  [CategoryName.Mushrooms]: 'Гриби',
  [CategoryName.FreshFish]: 'Свіжа риба',
  [CategoryName.FishDelicacies_Seafood]: 'Рибні делікатеси та морепродукти',
  [CategoryName.Grocery]: 'Бакалія',
  [CategoryName.Bread_Baking]: 'Хліб та випічка',
  [CategoryName.Sweets_Snacks]: 'Солодощі та снеки',
  [CategoryName.Drinks]: 'Напої',
  [CategoryName.Conservation]: 'Консервація',
  [CategoryName.FrozenFood]: 'Заморожені продукти',
  [CategoryName.Animals_Livestock]: 'Тварини та худоба',
  [CategoryName.Plants_Flowers]: 'Квіти та рослини',
  [CategoryName.Seedlings]: 'Саджанці та розсада',
  [CategoryName.PoultryMeat]: 'М’ясо птиці',
};

export const unitToTranslationMap: Record<Unit, string> = {
  KG: 'Кг',
  L: 'Л',
  PCS: 'Шт',
};
