import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ProductStatus } from '@/utils/constants';

import {
  ProductCardsList,
  IProductCardsListProps,
} from '../product-cards-list';

export interface IShopActiveProductsProps extends IProductCardsListProps {
  isUserShop?: boolean;
}

const noProductsText = 'Товари за вибраною категорією відсутні 🙈';

export function ShopActiveProducts({
  products,
  ...productsProps
}: IShopActiveProductsProps) {
  const activeProducts = useMemo(
    () =>
      products?.filter((product) => product.status === ProductStatus.Active),
    [products],
  );

  return (
    <>
      {!activeProducts?.length && (
        <Box sx={{ p: 2 }}>
          <Typography variant="body1">{noProductsText}</Typography>
        </Box>
      )}
      <ProductCardsList
        {...productsProps}
        products={activeProducts}
        sx={{ pt: 4, pb: 4, columnGap: 1 }}
      />
    </>
  );
}
