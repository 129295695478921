export const getProductsCountOnMapText = (
  productsCount?: number,
  countLoading = false,
) => {
  if (productsCount === undefined || countLoading) return 'Завантаження...';

  if (productsCount === 0) return 'Відсутні товари на ділянці мапи';

  const countString = productsCount.toFixed();
  const defaultText = `${countString} товарів на ділянці мапи`;

  switch (countString[countString.length - 1]) {
    case '1':
      return `${countString} товар на ділянці мапи`;
    case '2':
    case '3':
    case '4':
      return countString.length < 2 ||
        countString[countString.length - 2] !== '1'
        ? `${countString} товари на ділянці мапи`
        : defaultText;
    default:
      return defaultText;
  }
};
