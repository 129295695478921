import React, { useCallback, useState } from 'react';
import Image from 'next/image';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useOpenState } from '@/hooks/use-open-state';

import { FullScreenGallery } from '../full-screen-gallery';

const SHOP_PHOTO_HEIGHT = 72;

interface IExtendedPhotoProps {
  src: string;
  key?: string | number;
  icon?: JSX.Element;
}

export interface IShopPhotoListProps {
  photoUrls?: string[] | IExtendedPhotoProps[];
  boxProps?: BoxProps;
}

export function ShopPhotoList({ photoUrls, boxProps }: IShopPhotoListProps) {
  const [photoIndexToShow, setPhotoIndexToShow] = useState<number | undefined>(
    undefined,
  );

  const {
    open: openGallery,
    onOpen: onOpenGallery,
    onClose: onCloseGallery,
  } = useOpenState();

  const handleOnOpen = (photoIndex: number) => () => {
    setPhotoIndexToShow(photoIndex);
    onOpenGallery();
  };

  const onClose = useCallback(() => {
    setPhotoIndexToShow(undefined);
    onCloseGallery();
  }, [onCloseGallery]);

  return (
    <Box
      {...boxProps}
      sx={{
        px: 2,
        py: 1,
        display: 'flex',
        flexWrap: 'nowrap',
        flexShrink: 0,
        overflow: 'auto',
        ...boxProps?.sx,
      }}
    >
      {!photoUrls?.length ? (
        <ShopPhotoEmptyList />
      ) : (
        photoUrls.map((imageSrc, index) =>
          typeof imageSrc === 'string' ? (
            <ShopPhoto
              key={imageSrc}
              src={imageSrc}
              onClick={handleOnOpen(index)}
            />
          ) : (
            <ShopPhoto
              key={imageSrc.key || imageSrc.src}
              {...imageSrc}
              onClick={handleOnOpen(index)}
            />
          ),
        )
      )}
      <FullScreenGallery
        imageUrls={photoUrls?.map((imageSrc) =>
          typeof imageSrc === 'string' ? imageSrc : imageSrc.src,
        )}
        imageIndex={photoIndexToShow}
        open={openGallery}
        onClose={onClose}
      />
    </Box>
  );
}

const shopPhotoEmptyListText = 'фото господарства відсутні';

function ShopPhotoEmptyList() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        backgroundColor: 'background.default',
        height: SHOP_PHOTO_HEIGHT,
        width: 186,
      }}
    >
      <Typography variant="caption" color="text.secondary" align="center">
        {shopPhotoEmptyListText}
      </Typography>
    </Box>
  );
}

interface IShopPhotoProps {
  src: string;
  icon?: JSX.Element;
  onClick?: () => void;
}

function ShopPhoto({ src, icon, onClick }: IShopPhotoProps) {
  return (
    <Box
      onClick={onClick}
      sx={{
        flexShrink: 0,
        cursor: 'pointer',
        position: 'relative',
        height: SHOP_PHOTO_HEIGHT,
        '&:not(:last-child)': {
          mr: 1,
        },
        '& > *:not(img,button)': {
          position: 'unset !important',
          display: 'unset !important',
          height: '100% !important',
        },
        '& img': {
          borderRadius: 1,
          position: 'unset !important',
          width: 'auto !important',
          minWidth: 'unset !important',
          margin: 'unset !important',
        },
      }}
    >
      <Image src={src} objectFit="contain" layout="fill" alt="" />
      {icon}
    </Box>
  );
}
