import React from 'react';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';

import { useAppSelector } from '@/store/app';
import { selectDraftProductDataOrDefaults } from '@/store/domains';

import { ProductAddView } from '../product-add-view';
import { ProductEditView } from '../product-edit-view';
import { ProductView, IProductViewProps } from '../product-view';

export interface IProductSidePanel extends Omit<IProductViewProps, 'product'> {
  product?: IProductDTO;
  openInFrame?: boolean;
  activeTab: ProductViewTab;
  onTabChange?: (tab: ProductViewTab) => void;
}

export function ProductSidePanel({
  product,
  activeTab,
  ...props
}: IProductSidePanel) {
  const theme = useTheme();
  const draftProductData = useAppSelector(selectDraftProductDataOrDefaults);
  const open = !!product || activeTab === 'add-product';

  const renderProductAddView = () => {
    return (
      <ProductAddView
        defaultValues={draftProductData}
        onClose={props.onClose}
      />
    );
  };

  const renderProductEditView = () => {
    if (!product) {
      return null;
    }

    return <ProductEditView product={product} onClose={props.onClose} />;
  };

  const renderProductView = () => {
    if (!product) {
      return null;
    }

    return <ProductView product={product} {...props} />;
  };

  const renderProductTab = () => {
    switch (activeTab) {
      case 'product':
        return renderProductView();

      case 'edit-product':
        return renderProductEditView();

      case 'add-product':
        return renderProductAddView();
    }
  };

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={open}
      sx={{
        width: 360,
        flexShrink: 0,
        zIndex: !open ? -1 : 'drawer',
      }}
      PaperProps={{
        sx: {
          width: 360,
          boxShadow: 12,
          borderLeft: 0,
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        },
      }}
    >
      {renderProductTab()}
    </Drawer>
  );
}
