import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { ShopViewTabs, IShopViewTabsProps } from '../shop-view-tabs';

interface IShopViewTabContentProps {
  activeTab: ShopViewTab;
  productsTab: React.ReactElement;
  contactsTab: React.ReactElement;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void;
}

function ShopViewTabContent({
  activeTab,
  contactsTab,
  productsTab,
}: IShopViewTabContentProps) {
  switch (activeTab) {
    case 'products':
      return productsTab;
    case 'contacts':
      return contactsTab;
    default:
      return null;
  }
}

export type IShopViewTabsPanelProps = IShopViewTabContentProps &
  IShopViewTabsProps;

export function ShopViewTabsPanel({
  onScroll,
  ...props
}: IShopViewTabsPanelProps) {
  return (
    <>
      <ShopViewTabs {...props} />
      <ContentWrapper onScroll={onScroll}>
        <ShopViewTabContent {...props} />
      </ContentWrapper>
    </>
  );
}

const ContentWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  overflow: 'auto',
  flexGrow: 1,
}));
