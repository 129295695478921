import React, { useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { IAddressData, useAddressData } from '@/hooks/use-geocode';
import { isPresent } from '@/utils/is-present';
import { ProductStatus } from '@/utils/constants';

import { DrawerLayout } from '../drawer-layout';
import { ShopPhotoList } from '../shop-photo-list';
import { ShopViewTabsPanel } from '../shop-view-tabs-panel';
import { ShopContactsView } from '../shop-contacts-view';
import type { IProductCardsListProps } from '../product-cards-list';
import { AddProductButton } from '../add-product-button';
import { UserShopFooter } from '../user-shop-footer';
import { ShopActiveProducts } from '../shop-active-products';
import { ShopDeactivatedProducts } from '../shop-deactivated-products';
import { ShopHeaderNotification } from '../shop-header-notification';

const addressLoadingText = 'Завантаження...';
const noAddressText = 'Адреса не вказана';

const formatShopAddress = ({ city, state, country }: IAddressData) =>
  [city, state, country].filter(isPresent).join(', ').trim() || noAddressText;

export interface IShopViewLayoutProps {
  shop: IShopDTO;
  isUserShop: boolean;
  activeTab: ShopViewTab;
  closeButton: React.ReactNode;
  actionButton: React.ReactNode;
  productsProps: IProductCardsListProps;
  onTabChange?: (tab: ShopViewTab) => void;
  onProductAdd?: () => void;
}

const HEADER_HEIGHT = 210;

export function ShopViewLayout({
  shop,
  activeTab,
  isUserShop,
  closeButton,
  onTabChange,
  onProductAdd,
  actionButton,
  productsProps: { products, ...productsProps },
}: IShopViewLayoutProps) {
  const [address, isAddressLoading] = useAddressData(shop.address);
  const [isCollapsedHeader, setIsCollapsedHeader] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number | string>('auto');

  const activeProducts = useMemo(
    () =>
      products?.filter((product) => product.status === ProductStatus.Active),
    [products],
  );

  useEffect(() => {
    if (headerRef.current) {
      setTimeout(() => {
        setHeaderHeight(headerRef.current?.offsetHeight ?? 'auto');
      }, 100);
    }
  }, []);

  const handleOnScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (!isCollapsedHeader && clientHeight + HEADER_HEIGHT >= scrollHeight) {
      return;
    }

    if (scrollTop > 50 && isCollapsedHeader === false) {
      setIsCollapsedHeader(true);
    } else if (scrollTop <= 50 && isCollapsedHeader === true) {
      setIsCollapsedHeader(false);
    }
  };

  return (
    <DrawerLayout>
      {isUserShop && (
        <ShopHeaderNotification
          shop={shop}
          products={products}
          onProductAdd={onProductAdd}
        />
      )}
      <DrawerLayout.Header
        sx={{
          background:
            'linear-gradient(to right, rgba(231, 250, 148, 1) 30%, rgba(102, 177, 255, 1) 100%)',
        }}
      >
        {closeButton}
        {actionButton}
      </DrawerLayout.Header>
      <DrawerLayout.Content sx={{ overflow: 'hidden' }}>
        <Box
          ref={headerRef}
          sx={{
            height: isCollapsedHeader ? '0' : headerHeight,
            opacity: isCollapsedHeader ? 0 : 1,
            transition: 'height 0.3s, opacity 0.3s',
          }}
        >
          <Box sx={{ px: 2, pt: 2, pb: 1 }}>
            <Typography variant="h6">{shop.name}</Typography>
            <Typography
              variant="caption"
              mt={0.5}
              display="block"
              color="text.secondary"
            >
              {isAddressLoading
                ? addressLoadingText
                : address
                ? formatShopAddress(address)
                : noAddressText}
            </Typography>
          </Box>
          <ShopPhotoList photoUrls={shop.imageUrls} />
        </Box>

        <ShopViewTabsPanel
          activeTab={activeTab}
          onTabChange={onTabChange}
          productsCount={activeProducts?.length}
          onScroll={handleOnScroll}
          contactsTab={
            <ShopContactsView shopId={shop.id} phone={shop.phoneNumber} />
          }
          productsTab={
            <>
              <ShopActiveProducts
                products={activeProducts}
                {...productsProps}
              />
              {isUserShop && (
                <ShopDeactivatedProducts
                  products={products}
                  {...productsProps}
                />
              )}
            </>
          }
        />
        {isUserShop && activeTab === 'products' && (
          <AddProductButton onClick={onProductAdd} />
        )}
      </DrawerLayout.Content>
      <DrawerLayout.Footer footerType="flat">
        {isUserShop && <UserShopFooter shopId={shop.id} />}
      </DrawerLayout.Footer>
    </DrawerLayout>
  );
}
