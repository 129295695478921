import { useCallback, useMemo } from 'react';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Error from '@mui/icons-material/ErrorOutline';

import { useAppDispatch, useAppSelector } from '@/store/app';
import { selectIsUserShopUpdating, toggleShopStatus } from '@/store/domains';
import { ShopStatus } from '@/utils/constants';

import { DrawerLayout } from '../drawer-layout';
import { Loader } from '../loader';

const activateShopText = 'Активувати лавку';
const shopDeactivateText =
  'Твоя лавка cхована і не відображаєтся на мапі та пошуку.';

const addProductText = 'Додати оголошення';
const noProductsText =
  'У твоїй лавці відсутні оголошення, тому вона не відображаєтся на мапі.';

export interface IShopHeaderNotification {
  shop: IShopDTO;
  products?: IProductDTO[];
  onProductAdd?(): void;
}

const ActionButton = styled(Button)(({ theme }) => ({
  padding: 0,
  fontSize: 14,
  color: 'inherit',
  textTransform: 'none',
  textDecoration: 'underline',
  marginTop: theme.spacing(-0.25),
}));

export function ShopHeaderNotification({
  shop,
  products,
  onProductAdd,
}: IShopHeaderNotification) {
  const dispatch = useAppDispatch();
  const isUpdating = useAppSelector(selectIsUserShopUpdating);

  const onActivateClicked = useCallback(
    () =>
      dispatch(toggleShopStatus({ id: shop.id, status: ShopStatus.Active })),
    [shop, dispatch],
  );

  const { warningText, actionButton } =
    useMemo(() => {
      if (shop.status === ShopStatus.Deactivated) {
        return {
          warningText: shopDeactivateText,
          actionButton: (
            <ActionButton
              size="small"
              variant="text"
              disabled={isUpdating}
              onClick={onActivateClicked}
            >
              {activateShopText}
            </ActionButton>
          ),
        };
      }

      if (!products?.length) {
        return {
          warningText: noProductsText,
          actionButton: (
            <ActionButton
              size="small"
              variant="text"
              disabled={isUpdating}
              onClick={onProductAdd}
            >
              {addProductText}
            </ActionButton>
          ),
        };
      }

      return null;
    }, [shop, products, isUpdating, onProductAdd, onActivateClicked]) || {};

  if (shop.status === ShopStatus.Active && products?.length) {
    return null;
  }

  if (!warningText) {
    return null;
  }

  return (
    <DrawerLayout.Header
      sx={{
        fontSize: 14,
        minHeight: 96,
        color: 'background.paper',
        backgroundColor: 'error.main',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateRows: '1fr',
          gridTemplateColumns: '36px 1fr',
        }}
      >
        <Box
          sx={{
            gridRow: '1',
            gridColumn: '1',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {isUpdating ? (
            <Loader
              size={21}
              color="inherit"
              sx={{ height: 'auto', alignSelf: 'baseline' }}
            />
          ) : (
            <Error />
          )}
        </Box>
        <Box sx={{ gridRow: '1', gridColumn: '2' }}>
          {warningText}
          <br />
          {actionButton}
        </Box>
      </Box>
    </DrawerLayout.Header>
  );
}
