import Box, { BoxProps } from '@mui/material/Box';

import { Header } from './drawer-layout.header';
import { Content } from './drawer-layout.content';
import { Footer } from './drawer-layout.footer';

export interface IDrawerLayoutProps {
  children: React.ReactNode;

  boxProps?: BoxProps;
}

export function DrawerLayout({ children, boxProps }: IDrawerLayoutProps) {
  return (
    <Box
      {...boxProps}
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        ...boxProps?.sx,
      }}
    >
      {children}
    </Box>
  );
}

DrawerLayout.Header = Header;
DrawerLayout.Content = Content;
DrawerLayout.Footer = Footer;
