import { isBrowser } from './is-browser';

export const iOSMobile =
  isBrowser &&
  typeof window.navigator !== 'undefined' &&
  /iPad|iPhone|iPod/.test(window.navigator.userAgent);

export const iOSMobileMenuTransitionDurationFix = iOSMobile
  ? { appear: 0, enter: 0, exit: 0 }
  : 'auto';
