import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';

import { isBrowser } from '@/utils/is-browser';

import { ShopViewLayout, IShopViewLayoutProps } from '../shop-view-layout';
import type { IProductCardsListProps } from '../product-cards-list';
import { SecondaryCloseButton } from '../close-button';
import { ShopActionsMenuButton } from '../shop-actions-menu-button';
import { ShopEditView } from '../shop-edit-view';
import { SecondaryBackButton } from '../back-button';

export interface IShopSidePanelProps extends IProductCardsListProps {
  shop?: IShopDTO;
  shopLink?: string;
  isUserShop: boolean;
  onClose?: () => void;
  activeTab: ShopViewTab;
  onTabChange?: (tab: ShopViewTab) => void;
  onProductAdd: IShopViewLayoutProps['onProductAdd'];
}

export function ShopSidePanel({
  shop,
  shopLink,
  onClose,
  activeTab,
  isUserShop,
  onTabChange,
  onProductAdd,
  ...productsProps
}: IShopSidePanelProps) {
  const theme = useTheme();

  const isShopExist = !!shop;
  const [open, setOpen] = useState(isShopExist);

  useEffect(() => {
    if (!isBrowser) return;
    // Drawer is open in frame with a purpose to have smooth animation
    const frameId = requestAnimationFrame(() => setOpen(isShopExist));
    return () => cancelAnimationFrame(frameId);
  }, [isShopExist]);

  const renderShopViewLayout = () => {
    if (!shop) {
      return null;
    }

    return (
      <ShopViewLayout
        shop={shop}
        activeTab={activeTab}
        isUserShop={isUserShop}
        onTabChange={onTabChange}
        onProductAdd={onProductAdd}
        productsProps={productsProps}
        closeButton={<SecondaryCloseButton onClick={onClose} />}
        actionButton={
          <ShopActionsMenuButton shopLink={shopLink} shopName={shop.name} />
        }
      />
    );
  };

  const renderShopEditView = () => {
    if (!shop) {
      return null;
    }

    return (
      <ShopEditView
        shop={shop}
        onClose={onClose}
        closeButton={
          <SecondaryBackButton
            sx={{ borderRadius: 1 }}
            onClick={() => onTabChange?.('products')}
          />
        }
      />
    );
  };

  const renderShopTab = () => {
    switch (activeTab) {
      case 'contacts':
      case 'products':
        return renderShopViewLayout();

      case 'edit-shop':
        return renderShopEditView();

      default:
        return null;
    }
  };

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={open}
      PaperProps={{
        sx: {
          width: 360,
          flexShrink: 0,
          boxShadow: 12,
          borderLeft: 0,
          zIndex: !open ? -1 : 'drawer',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
        },
      }}
    >
      {renderShopTab()}
    </Drawer>
  );
}
