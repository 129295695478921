export function readFileAsDataUrl(file: Blob) {
  return new Promise<string>(function (resolve, reject) {
    const fr = new FileReader();

    fr.onload = function () {
      resolve(fr?.result?.toString() ?? '');
    };

    fr.onerror = function () {
      reject(fr);
    };

    fr.readAsDataURL(file);
  });
}
