import { useCallback } from 'react';
import { useRouter } from 'next/router';
import Button from '@mui/material/Button';
import { Formik } from 'formik';

import { useAppDispatch, useAppSelector } from '@/store/app';
import {
  createProduct,
  CreateProductData,
  IDraftProductData,
  isDraftProductData,
  removeDraftProduct,
  selectHasUserShop,
  selectIsUserProductsResourceCreating,
  updateDraftProduct,
} from '@/store/domains';
import { productSchemaValidator } from '@/utils/validators';

import { Loader } from '../loader';
import { DrawerLayout } from '../drawer-layout';
import { SecondaryBackButton } from '../back-button';
import { ProductEditableSection } from '../product-editable-section';
import { StyledForm } from '../titled-form-layout';

export interface IProductAddViewProps {
  onClose?: () => void;
  defaultValues: IDraftProductData;
}

export type ProductFormData = Omit<CreateProductData, 'imageUrls'> & {
  imageUrls: Array<ICreateImageDTO>;
};

export const convertToProductFormData = (
  data: IProductDTO | IDraftProductData,
): ProductFormData => {
  if (isDraftProductData(data)) {
    return data;
  }

  return {
    ...data,
    imageUrls: data?.imageUrls?.map((src) => ({ src })) ?? [],
  };
};

export function ProductAddView({
  onClose,
  defaultValues,
}: IProductAddViewProps) {
  const { push } = useRouter();
  const dispatch = useAppDispatch();
  const hasUserShop = useAppSelector(selectHasUserShop);
  const isLoading = useAppSelector(selectIsUserProductsResourceCreating);

  const handleOnClose = useCallback(() => {
    if (hasUserShop) {
      dispatch(removeDraftProduct());
    }
    onClose?.();
  }, [hasUserShop, onClose, dispatch]);

  const handleOnSubmit = (data: ProductFormData) => {
    // console.log({ productBase, data, userShopExists });
    if (hasUserShop) {
      dispatch(createProduct(data)).then((result) => {
        // eslint-disable-next-line no-console
        console.log({ result });

        if ('error' in result) return;

        handleOnClose();
      });
    } else {
      dispatch(updateDraftProduct(data));
      push('/sign-in');
    }
  };

  const headerTitle = 'Додати товар';

  return (
    <DrawerLayout>
      <Formik
        onSubmit={handleOnSubmit}
        validationSchema={productSchemaValidator}
        initialValues={convertToProductFormData(defaultValues)}
      >
        {({ errors, dirty, submitForm, setErrors }) => {
          const isDisabled =
            isLoading || Object.keys(errors || {}).length > 0 || !dirty;

          return (
            <>
              <DrawerLayout.Header
                headerTitle={headerTitle}
                sx={{
                  color: 'background.paper',
                  backgroundColor: 'primary.main',
                }}
              >
                <SecondaryBackButton
                  sx={{ borderRadius: 1 }}
                  onClick={handleOnClose}
                />
              </DrawerLayout.Header>

              <DrawerLayout.Content
                sx={{ backgroundColor: 'background.default' }}
              >
                <StyledForm
                  sx={{
                    p: 2,
                    ['& > *:not(:last-child)']: {
                      mb: 2,
                    },
                    ['.MuiOutlinedInput-root']: {
                      backgroundColor: 'background.paper',
                      '&:hover': { backgroundColor: 'background.paper' },
                    },
                  }}
                >
                  <ProductEditableSection setErrors={setErrors} />
                </StyledForm>
              </DrawerLayout.Content>

              <DrawerLayout.Footer>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isDisabled}
                  onClick={submitForm}
                  sx={{ borderRadius: 24, fontWeight: 'bold', py: 2 }}
                  startIcon={
                    isLoading ? <Loader size={24} color="inherit" /> : undefined
                  }
                >
                  {isLoading ? 'Зберігаємо' : 'Зберегти'}
                </Button>
              </DrawerLayout.Footer>
            </>
          );
        }}
      </Formik>
    </DrawerLayout>
  );
}
