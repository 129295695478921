import { useEffect, useRef, useState } from 'react';
import Geocode from 'react-geocode';
import getConfig from 'next/config';

import { LANGUAGE_CODE } from '@/utils/constants';

const {
  publicRuntimeConfig: { IS_DEVELOPMENT, GOOGLE_MAPS_API_KEY },
} = getConfig();

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
Geocode.setLanguage(LANGUAGE_CODE);
Geocode.setRegion(LANGUAGE_CODE);

if (IS_DEVELOPMENT) {
  Geocode.enableDebug();
}

interface IGeocodeBounds {
  northeast: ICoordinates;
  southwest: ICoordinates;
}

function extractBounds(bounds?: IGeocodeBounds) {
  return (
    bounds && {
      east: bounds.northeast.lng,
      north: bounds.northeast.lat,
      west: bounds.southwest.lng,
      south: bounds.southwest.lat,
    }
  );
}

export interface IAddressData {
  city?: string;
  state?: string;
  country?: string;
  streetAddress?: string;
}

export async function getAddressDataFromCoordinates({
  lat,
  lng,
}: ICoordinates) {
  const response = await Geocode.fromLatLng(lat.toString(), lng.toString());
  let city, state, country, streetAddress;
  for (let i = 0; i < response.results[0].address_components.length; i++) {
    for (
      let j = 0;
      j < response.results[0].address_components[i].types.length;
      j++
    ) {
      switch (response.results[0].address_components[i].types[j]) {
        case 'locality':
          city = response.results[0].address_components[i].long_name;
          break;
        case 'administrative_area_level_1':
          state = response.results[0].address_components[i].long_name;
          break;
        case 'country':
          country = response.results[0].address_components[i].long_name;
          break;
        case 'street-address':
          streetAddress = response.results[0].address_components[i].long_name;
          break;
      }
    }
  }
  return { city, state, country, streetAddress };
}

export async function getAddressDataFromFormattedAddress(
  formattedAddress: string,
) {
  const response = await Geocode.fromAddress(formattedAddress);
  const { bounds, viewport } = response.results[0]?.geometry ?? {};

  let city, state, country, streetAddress;
  for (let i = 0; i < response.results[0].address_components.length; i++) {
    for (
      let j = 0;
      j < response.results[0].address_components[i].types.length;
      j++
    ) {
      switch (response.results[0].address_components[i].types[j]) {
        case 'locality':
          city = response.results[0].address_components[i].long_name;
          break;
        case 'administrative_area_level_1':
          state = response.results[0].address_components[i].long_name;
          break;
        case 'country':
          country = response.results[0].address_components[i].long_name;
          break;
        case 'route':
          streetAddress = response.results[0].address_components[i].long_name;
          break;
      }
    }
  }

  return {
    city,
    state,
    country,
    streetAddress,
    bounds: extractBounds(bounds ?? viewport),
  };
}

export const useAddressData = (addressOrCoordinates: string | ICoordinates) => {
  const mountedRef = useRef(false);

  const [isLoading, setIsLoading] = useState(false);
  const [addressData, setAddressData] = useState<IAddressData | undefined>();

  useEffect(() => {
    mountedRef.current = true;

    setIsLoading(true);
    (typeof addressOrCoordinates === 'string'
      ? getAddressDataFromFormattedAddress(addressOrCoordinates)
      : getAddressDataFromCoordinates(addressOrCoordinates)
    )
      .then((address) => {
        if (mountedRef.current) {
          setAddressData(address);
        }
      })
      .catch(() => {
        if (mountedRef.current) {
          setAddressData(undefined);
        }
      })
      .finally(() => {
        if (mountedRef.current) {
          setIsLoading(false);
        }
      });

    return () => {
      mountedRef.current = false;
    };
  }, [addressOrCoordinates]);

  return [addressData, isLoading] as const;
};
