import { useCallback } from 'react';

import { isBrowser } from '@/utils/is-browser';

type FacebookPixelTracker = (
  event: string,
  payload?: Record<string, string | number | (string | number)[]>,
) => void;

function getFacebookPixelTracker(): FacebookPixelTracker | undefined {
  if (!isBrowser) return;

  const fbq = (window as { fbq?: (...args: unknown[]) => void }).fbq;
  if (!fbq || typeof fbq !== 'function') return;

  return (...args) => {
    try {
      fbq('track', ...args);
    } catch (error) {
      // Ignore track error
    }
  };
}

export const useFacebookPixelTracker = () =>
  useCallback<FacebookPixelTracker>((...args) => {
    const tracker = getFacebookPixelTracker();
    tracker?.(...args);
  }, []);

export const useFacebookPixelContactPhoneClickTracker = (
  shopId: string,
  phoneNumber: string,
) => {
  const tracker = useFacebookPixelTracker();
  return useCallback(
    () =>
      tracker('InitiateCheckout', {
        content_category: shopId,
        content_ids: [phoneNumber],
      }),
    [phoneNumber, shopId, tracker],
  );
};
