import { useField } from 'formik';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export interface ISelectFieldProps {
  name: string;
  options?: Array<{ label: string; value: string }>;

  type?: 'select';
  props?: TextFieldProps;
}

export function SelectField({ name, props, options = [] }: ISelectFieldProps) {
  const [field, { error }] = useField(name);

  return (
    <TextField
      select
      {...field}
      {...props}
      error={Boolean(error)}
      helperText={error || props?.helperText}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
}
