import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { HeaderActionsBox } from '../header-actions-box';

export interface IHeaderBoxProps extends BoxProps {
  headerTitle?: string | React.ReactNode;
}

const HeaderBox = styled(Box)<IHeaderBoxProps>(({ theme, headerTitle }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  minHeight: headerTitle ? 128 : 90,
}));

export const Header = ({
  headerTitle: propHeaderTitle,
  children,
  ...props
}: IHeaderBoxProps) => {
  const headerTitle = useMemo(() => {
    if (typeof propHeaderTitle === 'string') {
      return <Typography sx={{ fontSize: 20 }}>{propHeaderTitle}</Typography>;
    }
    return propHeaderTitle;
  }, [propHeaderTitle]);

  return (
    <HeaderBox {...props} headerTitle={propHeaderTitle}>
      <HeaderActionsBox
        sx={{
          padding: 0,
          minHeight: 66,
          backgroundImage: 'none',
          backgroundColor: 'transparent',
        }}
        type="filled"
      >
        {children}
      </HeaderActionsBox>
      {headerTitle}
    </HeaderBox>
  );
};
