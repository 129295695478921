import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Visibility from '@mui/icons-material/Visibility';
import FormHelperText from '@mui/material/FormHelperText';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import type { ShopStatus } from '@/utils/constants';

interface IShopSettingsSectionProps {
  status: ShopStatus;
  isUpdating: boolean;
  onAccountDelete(): void;
  onActivateToggle(): void;
}

interface ISettingsData {
  buttonLabel: string;
  description: string;
  buttonIcon: React.ReactNode;
  buttonColor: 'warning' | 'success';
}

const settingsText: Record<ShopStatus, ISettingsData> = {
  active: {
    buttonColor: 'warning',
    buttonIcon: <VisibilityOff />,
    buttonLabel: 'Сховати лавку з мапи',
    description:
      'Лавка буде схована з мапи та пошуку, ти завжди зможеш її увімкнути назад при потребі.',
  },
  inactive: {
    buttonColor: 'warning',
    buttonIcon: <VisibilityOff />,
    buttonLabel: 'Сховати лавку з мапи',
    description:
      'Лавка буде схована з мапи та пошуку, ти завжди зможеш її увімкнути назад при потребі.',
  },
  deactivated: {
    buttonColor: 'success',
    buttonIcon: <Visibility />,
    buttonLabel: 'Активувати лавку',
    description:
      'Лавку буде показано на мапі та в пошуку, ти завжди зможеш її вимкнути назад при потребі.',
  },
};

export function ShopSettingsSection({
  status,
  isUpdating,
  // onAccountDelete,
  onActivateToggle,
}: IShopSettingsSectionProps) {
  const { buttonLabel, description, buttonColor } = useMemo(
    () => settingsText?.[status] ?? settingsText.active,
    [status],
  );
  // const { buttonIcon, buttonLabel, description, buttonColor } = useMemo(
  //   () => settingsText?.[status] ?? settingsText.active,
  //   [status],
  // );

  const shopDeactivateButton = useMemo(() => {
    return (
      <Button
        sx={{ p: 1 }}
        color={buttonColor}
        disabled={isUpdating}
        onClick={onActivateToggle}
      >
        {buttonLabel}
      </Button>
    );
  }, [buttonLabel, buttonColor, onActivateToggle, isUpdating]);

  return (
    <Box>
      <Divider orientation="horizontal" sx={{ width: '100%', mb: 2 }} />
      {shopDeactivateButton}
      <FormHelperText sx={{ mx: 1 }}>
        {description}
        <br />
        {/* <Button
          variant="text"
          disabled={isUpdating}
          onClick={onAccountDelete}
          sx={{
            p: 0,
            fontSize: 12,
            color: 'inherit',
            textTransform: 'none',
            textDecoration: 'underline',
          }}
        >
          Бажаю видалити назавжди
        </Button> */}
      </FormHelperText>
    </Box>
  );
}
