import { useField } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export interface IPhoneNumberFieldProps {
  name: string;
  countryCode: string;
  phoneNumberLength: number;

  type?: 'phoneNumber';
  props?: TextFieldProps;
}

const ALLOWED_CHARACTERS_REGEX = /^[()\d .+-]*$/;

export function PhoneNumberField({
  name,
  countryCode,
  phoneNumberLength,
  props: { helperText, ...props } = {},
}: IPhoneNumberFieldProps) {
  const [field, { error }, { setValue }] = useField(name);

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const { value } = event.target;
    if (
      ALLOWED_CHARACTERS_REGEX.test(value) &&
      value.length <= phoneNumberLength
    ) {
      setValue(value);
    }
  };

  return (
    <TextField
      {...field}
      {...props}
      onChange={handleOnChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">+{countryCode}</InputAdornment>
        ),
        ...props.InputProps,
      }}
      error={Boolean(error)}
      helperText={error || helperText}
    />
  );
}
