import React, { useState } from 'react';
import Image from 'next/image';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { useOpenState } from '@/hooks/use-open-state';

import { FullScreenGallery } from '../full-screen-gallery';

export interface IProductPhotoGalleryProps {
  photoUrls: string[];
}

const PHOTO_HEIGHT = 306;

export function ProductPhotoGallery({ photoUrls }: IProductPhotoGalleryProps) {
  const theme = useTheme();
  const [photoIndexToShow, setPhotoIndexToShow] = useState(0);

  const photoSrc = photoUrls[photoIndexToShow];
  const total = photoUrls.length;

  const onPreviousClick = () =>
    setPhotoIndexToShow((index) => (index === 0 ? total - 1 : index - 1));
  const onNextClick = () =>
    setPhotoIndexToShow((index) => (index === total - 1 ? 0 : index + 1));

  const {
    open: openGallery,
    onOpen: onOpenGallery,
    onClose: onCloseGallery,
  } = useOpenState();

  return (
    <Box sx={{ position: 'relative', height: PHOTO_HEIGHT, flexShrink: 0 }}>
      <Image
        layout="fill"
        objectFit="contain"
        src={photoSrc}
        onClick={onOpenGallery}
        style={{ cursor: 'pointer' }}
        priority
        alt=""
      />
      {photoUrls.length > 1 && (
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            bottom: theme.spacing(2),
          }}
        >
          <PrevNextPhotoControl
            current={photoIndexToShow + 1}
            total={total}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
          />
        </Box>
      )}
      <FullScreenGallery
        imageUrls={photoUrls}
        imageIndex={photoIndexToShow}
        open={openGallery}
        onClose={onCloseGallery}
      />
    </Box>
  );
}

interface IPrevNextPhotoControlProps {
  current: number;
  total: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
}

function PrevNextPhotoControl({
  current,
  total,
  onPreviousClick,
  onNextClick,
}: IPrevNextPhotoControlProps) {
  const { palette } = useTheme();

  const iconSx = {
    cursor: 'pointer',
    width: '18px !important',
    height: '18px !important',
    color: `${palette.secondary.contrastText} !important`,
    '&:hover': {
      color: `${palette.grey[300]} !important`,
    },
  };

  return (
    <Chip
      variant="filled"
      sx={{ color: 'secondary.contrastText', backgroundColor: 'action.active' }}
      label={`${current} / ${total}`}
      avatar={<ArrowBackIcon onClick={onPreviousClick} sx={iconSx} />}
      deleteIcon={<ArrowForwardIcon sx={iconSx} />}
      onDelete={onNextClick}
    />
  );
}
